<template>
  <div>
    <div class="custom-wrapper">
      <van-swipe :autoplay="3000" indicator-color="white" height="240" @change="onChange">
        <van-swipe-item v-for="(item, index) of swipeList" :key="index" :show-indicators="false">
          <img class="my-swipe-img" :src="item">
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <span>{{ current + 1 }}/6</span>
          </div>
        </template>
      </van-swipe>
      <div class="custom-price">
        <small>&yen;</small>
        <span>240.00</span>
        <small> 起</small>
      </div>
    </div>

    <div class="yc-title">趣墅惠州南昆山大观园·拾春墅</div>
    <div class="yc-address">
      <van-icon name="location-o" />
      <i>惠州市龙门县碧桂园南昆大观A区5901</i>
    </div>

    <hr class="hr gap-ml gap-mr">

    <div class="flex-row hotel-facilities">
      <div class="flex-item">
        <img src="img/5d661e16c30d1.jpg">
      </div>
      <div class="flex-item">
        <img src="img/5d662ab8436bc.jpg">
      </div>
      <div class="flex-item">
        <img src="img/5d662b8aa2a60.jpg">
      </div>
      <div class="flex-item">
        <img src="img/5d6620b54729b.jpg">
      </div>
      <div class="flex-item">
        <img src="img/5d49019e83de2.jpg">
      </div>
      <div class="flex-item">
        <img src="img/sets_1.png">
      </div>
    </div>

    <hr class="hr gap-ml gap-mr">

    <div class="gap-ml gap-mr">
      <van-row type="flex" gutter="12">
        <van-col :span="12">
          <div class="gap-mb">
            <img class="gap-mr" src="img/ico24-bed.png" width="24" height="24">
            <strong>宜居10人7床</strong>
          </div>
          <div>
            <div class="gap-mb">· 2×1.8米 大床 1张</div>
            <div class="gap-mb">· 2×1.8米 大床 1张</div>
            <div>· 2×1.8米 大床 1张</div>
          </div>
        </van-col>
        <van-col :span="12">
          <div class="gap-mb">
            <img class="gap-mr" src="img/ico24-balcony.png" width="24" height="24">
            <strong>5房2厅2阳台</strong>
          </div>
          <div>
            <div class="gap-mb">· 室内面积300平米</div>
            <div>· 花园面积100平米</div>
          </div>
        </van-col>
      </van-row>
    </div>

    <hr class="hr gap-ml gap-mr">

    <div class="simple-panel">
      <div class="simple-panel-title">房源概览</div>
      <div class="simple-panel-body">
        <p>床型分布：<br>
          一楼：1.5米单床房 独卫<br>
          二楼：1.8米单床房 独卫<br>
          负一楼：1.5米单床房，1.2米双床房，1.2米双床房<br>
          别墅配有全自动麻将桌、KTV、烧烤炉、温泉池、休闲阳台、开放式厨房；还可以在私人温泉池享受温泉带来的健康，
          这是一套养生娱乐于一体的度假别墅，是商务接待、轰趴聚会、公司团建的绝佳选择！</p>
      </div>
    </div>

    <hr class="hr gap-ml gap-mr">

    <div class="simple-panel">
      <div class="simple-panel-title">别墅亮点</div>
      <div class="simple-panel-body">
        <p>趣墅南昆大观度假别墅坐落于惠州龙门县，西北靠南昆山余脉，（东南）玉带河绕城而过，
          小溪流贯穿整个南昆大观项目，是名副其实的山水度假胜地。睡进大自然的怀抱，感受森林的呼吸，邂逅趣墅精心设计的主题别墅。</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HotelDetail",
  data() {
    return {
      current: 0,
      swipeList: [
        require('../../assets/hotel/5bc5a81a41c80.jpg'),
        require('../../assets/hotel/5bc5a816d2ad9.jpg'),
        require('../../assets/hotel/5c18bab1d5b37.jpg'),
        require('../../assets/hotel/5c18baf3bae74.jpg'),
        require('../../assets/hotel/5de89daa9b46c.jpg'),
        require('../../assets/hotel/5de89daa943eb.jpg'),
      ]
    }
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
  }
}
</script>

<style lang="scss" scoped>
.my-swipe-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.custom-indicator {
  position: absolute;
  bottom: 0;
  let: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: right;
  font-weight: bold;
  color: #fff;
  padding: 0 12px;
  background: linear-gradient(0, rgba(0,0,0,0.5), rgba(0,0,0,0));
}

.custom-wrapper {
  position: relative;
}
.custom-price {
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: #fff;
  font-size: 1.2em;
}
.custom-price small {
  font-size: 0.6em;
}

.yc-title {
  font-size: 22px;
  padding: 12px;
}
.yc-address {
  padding: 0 12px;
}
.yc-address i {
  vertical-align: middle;
  font-style: normal;
}

.hotel-facilities img {
  display: block;
  width: 100%;
}

.simple-panel {
  padding: 0 12px;
}
.simple-panel-title {
  font-size: 14px;
  font-weight: bold;
}
.simple-panel-body {
  line-height: 1.5;
}
</style>